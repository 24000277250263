import React, { useEffect } from "react"
import DeliveryManagement from "../components/spark-express/deliveryManagement"
import OnGoingDeliveryStatus from "../components/spark-express/ongoingDeliveryStatus"
import OnGoingDeliveryDetails from "../components/spark-express/ongoingDeliveryDetails"
import DeliveryAssignedAlert from "../components/spark-express/deliveryAssignedAlert"
import Map from "../components/spark-express/map"

import { useSetRecoilState } from "recoil"
import deliveryAssigned from "../recoil-state/deliveryAssignedAlert"

import LocationEmitter from "../components/spark-express/locationEmitter"

import AvailableDeliveriesReceiver from "../components/spark-express/availableDeliveriesReceiver"

const Delivery = () => {
  let alertRiderForNewDelivery = useSetRecoilState(deliveryAssigned)

  useEffect(() => {
    alertRiderForNewDelivery(true)
  }, [])
  return (
    <>
      <LocationEmitter />
      <AvailableDeliveriesReceiver />

      <Map />
      <OnGoingDeliveryStatus />
      <DeliveryManagement />
      <OnGoingDeliveryDetails />
      <DeliveryAssignedAlert />
    </>
  )
}

export default Delivery
